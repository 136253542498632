import React from 'react';
import { styled } from 'linaria/react';

import ornament from '../images/ornament.svg';
import ornamentSmall from '../images/ornament-small.svg';

const OrnamentImg = styled.img`
  position: absolute;
  transform: rotate(${({rotate}) => -45 + rotate}deg);
`;

function Ornament({styles = '', rotate = 0, small = false}) {
  return (
    <OrnamentImg
      rotate={rotate}
      src={small ? ornamentSmall : ornament}
      className={styles}
      alt=""
    />
  );
}

export default Ornament;
