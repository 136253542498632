import React from 'react';
import { styled } from 'linaria/react';

import { theme } from '../utils/theme';
import Link from './Link'

import photoDefault from '../images/photo.png';
import twitter from '../images/icons/twitter.svg';

const Card = styled.div`
  background: ${theme.colors.white};
  border-radius: 5px;
  border: 1px solid ${theme.colors.grey};
  height: 320px;
  width: 320px;
  margin: 0 5px;
  @media (min-width: 450px) {
    width: 450px;
  }
  @media (min-width: 650px) {
    width: 620px;
    margin: 0 50px;
  }
`;

const CardHeader = styled.div`
  align-items: center;
  background-color: ${theme.colors.lightGrey};
  border-bottom: 1px solid ${theme.colors.grey};
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: ${theme.colors.black};
  display: flex;
  flex-flow: row;
  height: 110px;
  padding: 15px;
  width: 100%;
  @media (min-width: 650px) {
    padding: 25px;
  }
  h3 {
    font-size: 16px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 5px;
    @media (min-width: 650px) {
      font-size: 18px;
      line-height: 35px;
      margin-bottom: 0;
    }
  }
  h4 {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    @media (min-width: 650px) {
      font-size: 18px;
    }
  }
  .twitter {
    margin-left: auto;
  }
`;

const Avatar = styled.img`
  border-radius: 50%;
  height: 50px;
  margin-right: 10px;
  width: 50px;
  @media (min-width: 650px) {
    height: 60px;
    margin-right: 20px;
    width: 60px;
  }
`;

const CardContent = styled.div`
  color: ${theme.colors.black};
  font-size: 18px;
  line-height: 28px;
  padding: 15px 30px;
  text-align: center;
  @media (min-width: 650px) {
    font-size: 24px;
    padding: 40px 80px;
  }
`;

function Testimonial({ author, title, children, link, photo=photoDefault }) {
  return (
    <Card>
      <CardHeader>
        <Avatar src={photo} alt="" />
        <div>
          <h3>{author}</h3>
          <h4>{title}</h4>
        </div>
        <Link to={link} className="twitter">
          <img src={twitter} alt="" />
        </Link>
      </CardHeader>
      <CardContent>{children}</CardContent>
    </Card>
  );
}

export default Testimonial;
