/* eslint-disable no-unused-vars */

import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';

import Section from '../layout/Section';
import { Heading } from '../typography';

import logo from '../../images/icons/logo.svg';

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const GridCell = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 100%;
  height: 90px;
  justify-content: center;
  @media (min-width: 500px) {
    flex-basis: calc(100% / 2);
  }
  @media (min-width: 800px) {
    flex-basis: calc(100% / 3);
  }
  @media (min-width: 1000px) {
    flex-basis: calc(100% / 4);
  }
`;

const sectionStyles = css`
  // padding-bottom: 50px;
`;

const headingStyles = css`
  margin-top: 0;
  margin-bottom: 0;
`;

function Companies() {
  return (
    <Section outerStyles={sectionStyles}>
      <Content>
        <Heading className={headingStyles}>
          They are already using <br />
          <span>react-native-paper</span>
        </Heading>
        {/*
        <Grid>
          <GridCell>
            <img src={logo} alt="" />
          </GridCell>
          <GridCell>
            <img src={logo} alt="" />
          </GridCell>
          <GridCell>
            <img src={logo} alt="" />
          </GridCell>
          <GridCell>
            <img src={logo} alt="" />
          </GridCell>
          <GridCell>
            <img src={logo} alt="" />
          </GridCell>
          <GridCell>
            <img src={logo} alt="" />
          </GridCell>
          <GridCell>
            <img src={logo} alt="" />
          </GridCell>
          <GridCell>
            <img src={logo} alt="" />
          </GridCell>
          <GridCell>
            <img src={logo} alt="" />
          </GridCell>
          <GridCell>
            <img src={logo} alt="" />
          </GridCell>
          <GridCell>
            <img src={logo} alt="" />
          </GridCell>
          <GridCell>
            <img src={logo} alt="" />
          </GridCell>
        </Grid>
            */}
      </Content>
    </Section>
  );
}

export default Companies;
