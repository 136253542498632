import React from 'react';

import Layout from '../components/layout/layout';
import Hero from '../components/homepage/Hero';
import Numbers from '../components/homepage/Numbers';
import LookAndFeel from '../components/homepage/LookAndFeel';
import Code from '../components/homepage/Code';
import KeyConcepts from '../components/homepage/KeyConcepts';
import Companies from '../components/homepage/Companies';
import Testimonials from '../components/homepage/Testimonials';
import Discord from '../components/homepage/Discord';
import Callstack from '../components/homepage/Callstack';
import MaterialYou from '../components/homepage/MaterialYou';

const IndexPage = (props) => (
  <Layout>
    <Hero />
    <Numbers />
    <LookAndFeel />
    <MaterialYou />
    <Code />
    <KeyConcepts />
    <Companies />
    <Testimonials />
    <Discord />
    <Callstack />
  </Layout>
);

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        apiUrl
      }
    }
  }
`

export default IndexPage;
