import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';

import Section from '../layout/Section';
import ConceptCard from '../ConceptCard';

import platform from '../../images/icons/platform-icon.svg';
import theming from '../../images/icons/theming-icon.svg';
import accesibility from '../../images/icons/accessibility-icon.svg';

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column;
  @media (min-width: 1000px) {
    flex-flow: row;
  }
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  & h2 {
    font-size: 4rem;
    margin-bottom: 1.5rem;
    margin-top: 0;
  }

  & p {
    max-width: 460px;
    margin-bottom: 4rem;
  }
  text-align: center;
`;

const sectionStyles = css`
  // padding-top: 120px;
`;

function KeyConcepts() {
  return (
    <Section dark outerStyles={sectionStyles}>
      <Content>
        <div>
          <h2>Key concepts</h2>
          <p>
            react-native-paper focuses in important concepts that are hard to
            build from scratch.
          </p>
        </div>
        <Row>
          <Column>
            <ConceptCard
              title="Platform adaptation"
              description="React-native-paper meets high expectations set by iOS and Android ecosystems. Your users will appreciate this choice."
              icon={platform}
            />
          </Column>
          <Column>
            <ConceptCard
              title="Full theming support"
              description="Every app is different - that’s why themes are first-class citizens in React-native-paper. Switch between dark and light modes, customise default colours or make your own. It’s never been that easy. "
              icon={theming}
            />
          </Column>
          <Column>
            <ConceptCard
              title="Accessibility and RTL support"
              description="React-native-paper is fully compatible with screen readers, readability tools and right-to-left languages. Make your app inclusive by default."
              icon={accesibility}
            />
          </Column>
        </Row>
      </Content>
    </Section>
  );
}

export default KeyConcepts;
