import React, { Component } from 'react';
import { styled } from 'linaria/react';
import { Swipeable } from 'react-swipeable';
import debounce from 'lodash/debounce';

import arrowLeft from '../images/arrow-left.svg';
import arrowRight from '../images/arrow-right.svg';

const Wrapper = styled.div`
  overflow-x: hidden;
  width: 100%;
  position: relative;
  height: 350px;
`;

const Inner = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: no-wrap;
  width: ${({ slidesAmount }) => slidesAmount * 320}px;
  transform: translate(
    ${({ currentSlide, windowWidth }) =>
      currentSlide * -320 + windowWidth / 2 + 160}px
  );
  transition: transform 0.3s ease-in-out;

  @media (min-width: 450px) {
    width: ${({ slidesAmount }) => slidesAmount * 450}px;
    transform: translate(
      ${({ currentSlide, windowWidth }) =>
        currentSlide * -450 + windowWidth / 2 + 225}px
    );
  }

  @media (min-width: 650px) {
    width: ${({ slidesAmount }) => slidesAmount * 620}px;
    transform: translate(
      ${({ currentSlide, windowWidth }) =>
        currentSlide * -620 + windowWidth / 2 + 310}px
    );
  }
`;

const ArrowContainer = styled.div`
  align-items: center;
  background: transparent;
  display: none;
  height: 100%;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 350px;
  @media (min-width: 650px) {
    display: flex;
    width: 630px;
  }
`;

const Arrow = styled.img`
  cursor: pointer;
  width: 40px;
`;

const ArrowLeft = styled(Arrow)`
  margin-right: auto;
`;

const ArrowRight = styled(Arrow)`
  margin-left: auto;
`;

class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      windowWidth:
        typeof document !== 'undefined' ? document.body.clientWidth : 0
    };

    this.handleResizeDebounced = debounce(this.handleResize, 300);
  }

  componentDidMount() {
    // setting initial slide here for correct Inner x position
    this.setState({ currentSlide: this.props.initialSlide });
    this.handleResize();
    window.addEventListener('resize', this.handleResizeDebounced);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResizeDebounced);
  }

  handleResize = () => {
    this.setState(() => ({ windowWidth: document.body.clientWidth }));
  };

  handlePrevSlide = () => {
    this.setState(prevState => {
      const { currentSlide } = prevState;

      if (currentSlide === 1) {
        return { currentSlide };
      }
      return { currentSlide: currentSlide - 1 };
    });
  };

  handleNextSlide = () => {
    const { slidesAmount } = this.props;

    this.setState(prevState => {
      const { currentSlide } = prevState;

      if (currentSlide === slidesAmount) {
        return { currentSlide };
      }
      return { currentSlide: currentSlide + 1 };
    });
  };

  render() {
    const { slidesAmount, children } = this.props;
    const { currentSlide, windowWidth } = this.state;

    return (
      <Wrapper>
        <Swipeable
          onSwipedLeft={this.handleNextSlide}
          onSwipedRight={this.handlePrevSlide}
        >
          <Inner
            slidesAmount={slidesAmount}
            currentSlide={currentSlide}
            windowWidth={windowWidth}
          >
            {children}
          </Inner>
          <ArrowContainer>
            {currentSlide > 1 && (
              <ArrowLeft
                src={arrowLeft}
                alt=""
                onClick={this.handlePrevSlide}
              />
            )}
            {currentSlide < slidesAmount && (
              <ArrowRight
                src={arrowRight}
                alt=""
                onClick={this.handleNextSlide}
              />
            )}
          </ArrowContainer>
        </Swipeable>
      </Wrapper>
    );
  }
}

export default Slider;
