import React from 'react';
import { css } from 'linaria';

import Section from '../layout/Section';
import Slider from '../Slider';
import Testimonial from '../Testimonial';

import kurt from '../../images/kurt.jpg';
import brent from '../../images/brent.jpg';
import osadnik from '../../images/osadnik.jpg';

const styles = {
  outer: css`
    padding: 0;
    height: 400px;
  `,
  inner: css`
    max-width: unset;
    overflow: hidden;
  `
};

function Testimonials() {
  return (
    <Section innerStyles={styles.inner} outerStyles={styles.outer}>
      <Slider slidesAmount={3} initialSlide={2}>
        <Testimonial
          author="Kurt Kemple"
          link="https://twitter.com/kurtiskemple/status/1089866560641093632"
          title="Co-organizer of @NYCGraphQL"
          photo={kurt}
        >
          Paper w/ RNW is a glorious thing! Universal design systems are where
          it's at. #alltheplatforms
        </Testimonial>
        <Testimonial
          author="brent"
          link="https://twitter.com/notbrent/status/1108492804978667520"
          title="Developer @ Expo / React Native"
          photo={brent}
        >
          react-native-paper is{' '}
          <span role="img" aria-label="cool">
            😎
          </span>{' '}
          ios + android + web, and exports typescript types.
        </Testimonial>
        <Testimonial
          author="Michał Osadnik"
          title="Software Engineer @SWMansion"
          link="https://twitter.com/mosdnk/status/1106184194458374149"
          photo={osadnik}
        >
          Still cannot believe how actively react-native-paper is maintained! Is
          it resurrection of Material Design on iOS in React Native?
        </Testimonial>
      </Slider>
    </Section>
  );
}

export default Testimonials;
