import React from 'react';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import { Element } from 'react-scroll';

import { theme } from '../../utils/theme';
import { PAPER_STATS_API } from '../../utils/constants';

import Section from '../layout/Section';
import Link from '../Link';
import { Heading } from '../typography';

import downloadIcon from '../../images/icons/download-icon.svg';
import starIcon from '../../images/icons/star-icon.svg';
import commitIcon from '../../images/icons/commit-icon.svg';

const styles = {
  heading: css`
    margin-bottom: 60px;
    margin-top: 0;
    @media (min-width: 768px) {
      //margin-bottom: 160px;
    }
  `,
  outer: css`
    // min-height: 800px;
    padding-bottom: 0;

    @media (min-width: 992px) {
      // padding-bottom: 100px;
      padding-top: 150px;
    }
  `
};

const NumbersItem = styled.li`
  display: flex;
  flex-flow: column;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 60px;
  }

  ${'' /* @media (min-width: 768px) {
    margin-bottom: 30px;
  } */}

  h3 {
    font-family: 'Platform Web';
    font-size: 72px;
    margin-top: 30px;
    margin-bottom: 0;

    @media (min-width: 768px) {
      margin-bottom: 15px;
    }
  }

  h4 {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2.57px;
    margin-bottom: 0;

    a {
      color: ${theme.colors.white};
    }
  }
`;

const NumbersList = styled.ul`
  padding: 0;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  @media (min-width: 768px) {
    flex-flow: row;
  }
`;

const fallbackData = {
  stars: 7371,
  commits: 1306,
  weeklyDownloads: 55300
}

function Numbers() {
  const [data, setData] = React.useState(fallbackData);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(PAPER_STATS_API);
        const data = await response.json();

        setData(data)
      } catch (error) {
        console.error('Error while fetching numbers', error);
      }
    }

    fetchData()
  }, [])

  return (
    <Element id="numbers">
      <Section dark outerStyles={styles.outer}>
        <Heading className={styles.heading}>
          It's free, it's Open Source!
        </Heading>
        <NumbersList>
          <NumbersItem>
            <img src={downloadIcon} alt="" />
            <h3>{data.weeklyDownloads.toLocaleString('en-US')}</h3>
            <h4>
              Downloads weekly on{' '}
              <Link to="https://www.npmjs.com/package/react-native-paper">
                npm
              </Link>
            </h4>
          </NumbersItem>
          <NumbersItem>
            <img src={starIcon} alt="" />
            <h3>{data.stars.toLocaleString('en-US')}</h3>
            <h4>
              Stars on{' '}
              <Link to="https://github.com/callstack/react-native-paper">
                GitHub
              </Link>
            </h4>
          </NumbersItem>
          <NumbersItem>
            <img src={commitIcon} alt="" />
            <h3>{data.commits.toLocaleString('en-US')}</h3>
            <h4>Number of commits</h4>
          </NumbersItem>
        </NumbersList>
      </Section>
    </Element>
  );
}

export default Numbers;
