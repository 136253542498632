import React from 'react';
import { css } from 'linaria';

import { theme } from '../../utils/theme';
import Section from '../layout/Section';

import materialYou from '../../images/material-you.png';

const styles = {
  wrapper: css`
    position: relative;
    padding-bottom: 0px;
    padding-top: 0px;
    min-height: 700px;
    @media (min-width: 401px) {
      min-height: 420px;
    }
    @media (min-width: 901px) {
      min-height: 700px;
    }
  `,
  container: css`
    display: flex;
    flex-flow: row;
    padding-bottom: 0px;

    @media (min-width: 901px) {
      padding-bottom: 0px;
    }
  `,
  heading: css`
    font-size: 52px;
    line-height: 120%;
    margin-top: 0;
    span {
      color: ${theme.colors.primary};
    }
    margin-bottom: 25px;
  `,
  body: css`
    font-size: 18px;
    color: #414757;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 10px;
  `,
  materialYouImage: css`
    width: auto;
    position: absolute;
    height: 720px;
    top: 20px;
    left: 0px;
  `,
  mobilesContainer: css`
    display: none;

    @media (min-width: 900px) {
      position: relative;
      display: flex;
      flex: 1;
    }
  `,
  heroCopy: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 30px;

    @media (min-width: 600px) {
      padding: 75px 35px 0 35px;
    }

    @media (min-width: 900px) {
      max-width: 495px;
    }
  `,
};

function MaterialYou() {
  return (
    <Section outerStyles={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.heroCopy}>
          <h1 className={styles.heading}>
            Paper supports <span>Material You</span>!
          </h1>
          <p className={styles.body}>
            React Native Paper library officially supports the new Material
            Design generation called Material You - a brand new and the most
            expressive design system by Google.
          </p>
          <p className={styles.body}>
            All of the Paper’s components have been adjusted to the latest
            standards of visual by changes in colors, typography and
            animations so you can build your apps according to the latest
            trends.
          </p>
        </div>
        <div className={styles.mobilesContainer}>
          <img
            src={materialYou}
            className={styles.materialYouImage}
            alt="mobile mockup"
          />
        </div>
      </div>
    </Section>
  );
}

export default MaterialYou;
