import { styled } from 'linaria/react';

import { theme } from '../../utils/theme';

const Heading = styled.h1`
  font-size: 48px;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 64px;
  }
  span {
    color: ${theme.colors.primary};
  }
`;

export default Heading;
