import React from 'react';
import { View, Image, ScrollView, StyleSheet } from 'react-native';
import {
  TextInput,
  Button,
  Title,
  Provider,
  Paragraph,
  Appbar,
  Snackbar,
  MD2LightTheme,
  MD3LightTheme,
  MD3DarkTheme,
  Text,
  Switch,
  Chip,
  Divider
} from 'react-native-paper';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live';
import { css } from 'linaria';
import ScrollContainer from 'react-indiana-drag-scroll';

import { code, md3Code, transformCode } from './exampleCode';
import { Heading } from '../typography';
import Ornament from '../Ornament';

import { theme } from '../../utils/theme';
import editorTheme from '../../utils/editorTheme';
import liveIcon from '../../images/icons/live-icon.svg';
import iphoneFrame from '../../images/ip13.png'

const customStyle = StyleSheet.create({
  row: {
    marginTop: 15,
    marginBottom: 15,
    flexDirection: 'row',
  },
  box: {
    padding: 15
  }
});

const scope = {
  View,
  TextInput,
  Button,
  Provider,
  Title,
  Paragraph,
  Appbar,
  Snackbar,
  Text,
  ScrollView,
  ScrollContainer,
  MD2LightTheme,
  MD3LightTheme,
  MD3DarkTheme,
  StyleSheet,
  Switch,
  Chip,
  Divider,
  customStyle,
};

const cssStyles = {
  numericLine: css`
    textarea {
      outline: none
    }

    pre {
      counter-reset: token-line;
    }
    
    .token-line::before {
      counter-increment: token-line;
      content: counter(token-line);
      position: absolute;
      left: -10px;
      overflow: visible;
      color: rgba(84,94,104);
    }
  `
};


const Editor = () => {
  const [isV3, setIsV3] = React.useState(true);

  return (
  <View style={styles.row}>
    <style type="text/css">
      {`
        @font-face {
          font-family: 'MaterialCommunityIcons';
          src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
        }
        .inputsWithoutOutline{
          overflow-y: auto !important;
          display: flex;
          flex-direction: column
        }
        .inputsWithoutOutline::-webkit-scrollbar {
          display: none !important
        }
        .inputsWithoutOutline input, .inputsWithoutOutline button{
          outline: none !important;
        }
      `}
    </style>
    <LiveProvider
      code={isV3 ? md3Code : code}
      scope={{...scope, isV3, setIsV3}}
      theme={editorTheme}
      transformCode={transformCode}
      language="jsx"
    >
      <View
        style={[
          styles.flexNoResize,
          { flexBasis: '50%', maxWidth: 460, position: 'relative' }
        ]}
      >
        <img src={liveIcon} style={{ alignSelf: 'start' }} alt="" />
        <Heading style={{ textAlign: 'left', marginTop: 30 }}>
          Try it <span>live</span>!
        </Heading>
        <Text
          style={{
            color: theme.colors.nearBlack,
            fontSize: 18,
            lineHeight: 28,
            fontFamily: 'Roboto',
            marginBottom: 50,
            maxWidth: 400
          }}
        >
          Try our live demo and check it out for yourself! When you are done,
          make sure to copy the code and drop it straight into a React Native
          app! Yes, Paper is cross-platform and works on both web and mobile.
        </Text>
        <div style={styles.liveEditorWrapper}>
          <LiveEditor
            style={styles.editor}
            padding={20}
            tabSize={2}
            rows={2}
            textareaId="liveEditorArea"
            autoFocus={false}
            className={cssStyles.numericLine}
          />
        </div>
        <Ornament styles={ornamentStyles} rotate={45} small />
      </View>
      <View style={[styles.flexNoResize, styles.previewWrapper]}>
        <Image source={{ uri: iphoneFrame }} style={styles.mobileImg} pointerEvents="none" />
        <LiveError style={styles.error} />
        <View style={styles.screen}>
          <LivePreview style={styles.preview} />
        </View>
      </View>
    </LiveProvider>
  </View>
)};

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 50,
    width: '100%'
  },
  editorWrapper: {
    padding: '.8em',
    borderRadius: '.3em',
    borderStyle: 'solid',
    borderWidth: '1pt'
  },
  editor: {
    width: '100%',
    maxWidth: 380,
    backgroundColor: '#202C32',
    outlineColor: 'rgba(33, 0, 93, 1)',
    caretColor: '#fff',
    // NOTE: styles below should match these in editorTheme
    fontFamily: '"Roboto Mono", monospace',
    fontSize: 16,
    borderRadius: 5,
    marginLeft: 20,
    overflow: 'visible',
  },
  error: {
    maxWidth: 380,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    color: '#D8000C',
    backgroundColor: '#FFBABA',
    paddingTop: 47,
    paddingLeft: 10,
    paddingRight: 10,
    width: 320,
    height: 710,
    top: 37,
    left: 30,
    position: 'absolute',
    zIndex: 2,
    borderRadius: 50,
  },
  screen: {
    width: 320,
    height: 710,
    top: 37,
    left: 30,
    position: 'absolute',
    backgroundColor: '#FFF'
  },
  preview: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: 50,
    border: `1px solid ${theme.primary}`
  },
  mobileImg: {
    width: 380,
    height: 793,
    zIndex: 3,
  },
  flexNoResize: {
    flexGrow: 0,
    flexShrink: 0
  },
  previewWrapper: {
    position: 'relative',
    bottom: -80
  },
  liveEditorWrapper: { 
    height: 400, 
    overflow: 'auto', 
    maxWidth: 400,
    backgroundColor: editorTheme.plain.backgroundColor,
    borderRadius: 5
  }
};

const ornamentStyles = css`
  top: 470px;
  left: 425px;
`;

export default Editor;
