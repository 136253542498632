const editorTheme = {
  plain: {
    backgroundColor: '#202C32',
    color: 'rgb(170, 176,179)',
    whiteSpace: 'break-word',
    // NOTE: styles below should match these in Editor.js
    fontFamily: '"Roboto Mono", monospace',
    borderRadius: 5,
    fontSize: '16px'
  },
  styles: [
    {
      types: [
        'prolog',
        'doctype',
        'cdata',
        'variable',
        'function-variable',
        'tag',
        'operator'
      ],
      style: {
        color: '#F92F5F'
      }
    },
    {
      types: ['property', 'attr-name'],
      style: {
        color: '#d7ad58'
      }
    },
    {
      types: ['comment'],
      style: {
        color: '#8295b6'
      }
    },
    {
      types: ['punctuation'],
      style: {
        color: 'rgb(170, 176,179)	'
      }
    },

    {
      types: ['attr-value', 'script', 'function'],
      style: {
        color: 'rgb(100,	172,	144)'
      }
    },
    {
      types: [
        'tag-id',
        'selector',
        'atrule-id',
        'keyword',
        'boolean',
        'string',
        'entity',
        'url',
        'control',
        'directive',
        'unit',
        'statement',
        'regex',
        'at-rule',
        'placeholder',
        'number'
      ],
      style: {
        color: 'rgb(173,	119, 215)'
      }
    },
    {
      types: ['deleted'],
      style: {
        textDecorationLine: 'line-through'
      }
    },
    {
      types: ['inserted'],
      style: {
        textDecorationLine: 'underline'
      }
    },
    {
      types: ['italic'],
      style: {
        fontStyle: 'italic'
      }
    },
    {
      types: ['important', 'bold'],
      style: {
        fontWeight: 'bold'
      }
    }
  ]
};

export default editorTheme;

export const editorTheme2 /*: PrismTheme */ = {
  plain: {
    backgroundColor: '#2a2734',
    color: '#9a86fd'
  },
  styles: [
    {
      types: ['comment', 'prolog', 'doctype', 'cdata', 'punctuation'],
      style: {
        color: '#6c6783'
      }
    },
    {
      types: ['namespace'],
      style: {
        opacity: 0.7
      }
    },
    {
      types: ['tag', 'operator', 'number'],
      style: {
        color: '#e09142'
      }
    },
    {
      types: ['property', 'function'],
      style: {
        color: '#9a86fd'
      }
    },
    {
      types: ['tag-id', 'selector', 'atrule-id'],
      style: {
        color: '#eeebff'
      }
    },
    {
      types: ['attr-name'],
      style: {
        color: '#c4b9fe'
      }
    },
    {
      types: [
        'boolean',
        'string',
        'entity',
        'url',
        'attr-value',
        'keyword',
        'control',
        'directive',
        'unit',
        'statement',
        'regex',
        'at-rule',
        'placeholder',
        'variable'
      ],
      style: {
        color: '#ffcc99'
      }
    },
    {
      types: ['deleted'],
      style: {
        textDecorationLine: 'line-through'
      }
    },
    {
      types: ['inserted'],
      style: {
        textDecorationLine: 'underline'
      }
    },
    {
      types: ['italic'],
      style: {
        fontStyle: 'italic'
      }
    },
    {
      types: ['important', 'bold'],
      style: {
        fontWeight: 'bold'
      }
    },
    {
      types: ['important'],
      style: {
        color: '#c4b9fe'
      }
    }
  ]
};

export const grayTheme = {
  plain: {
    backgroundColor: '#202C32',
    color: 'rgb(170, 176,179)',
    whiteSpace: 'break-word',
    // NOTE: styles below should match these in Editor.js
    fontFamily: '"Roboto Mono", monospace',
    borderRadius: 5,
    fontSize: '16px'
  },
  styles: [
    {
      types: [
        'prolog',
        'doctype',
        'cdata',
        'variable',
        'function-variable',
        'tag',
        'operator',
        'boolean',
        'string',
        'entity',
        'url',
        'attr-value',
        'keyword',
        'control',
        'directive',
        'unit',
        'statement',
        'regex',
        'at-rule',
        'placeholder',
        'variable'
      ],
      style: {
        color: 'rgb(170, 176,179)'
      }
    }
  ]
};
