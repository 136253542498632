import React from 'react';
import { css } from 'linaria';

import { theme } from '../../utils/theme';
import Section from '../layout/Section';
import { Heading } from '../typography';

import Editor from '../editor/Editor';
import CodeSnippet from '../editor/CodeSnippet';
import { grayTheme } from '../../utils/editorTheme';

const codeSnippetStandard = `
const Touchable = Platform.OS === 'ios' ?
  TouchableOpacity :
  TouchableWithNativeFeedback

const Button = ({ onPress }) =>
  <Touchable
    style={styles.button}
    onPress={onPress}
  >
    <Text style={styles.text}>
      Press me
    </Text>
  </Touchable>

const styles = StyleSheet.create({
  button: {
    // Styles here
  }
  text: {
    // Styles here
  }
});

`;

const codeSnippetPaper = `
<Button onPress={onPress}>
  Press me
</Button>

`;

const styles = {
  tagline: css`
    color: ${theme.colors.nearBlack};
    font-size: 30px;
    line-height: 35px;
    margin: 0 auto 70px auto;
    max-width: 500px;
    text-align: center;
  `,
  comparison: css`
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 50px;

    & h2 {
      font-family: 'Platform Web';
      font-size: 24px;
    }
    p {
      color: ${theme.colors.nearBlack};
      font-size: 18px;
      line-height: 28px;
    }
  `,
  expo: css`
    display: flex;
    flex-wrap: wrap;
    position: relative;

    h1 {
      text-align: left;
      margin-top: 30px;
    }

    p {
      color: ${theme.colors.nearBlack};
      font-size: 18px;
      line-height: 28px;
      width: 400px;
    }
  `,
  expoWrapper: css`
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-end;
    min-width: 380px;

    @media (min-width: 840px) {
      justify-content: flex-end;
    }
  `,
  expoImage: css`
    width: 380px;
    position: relative;
    bottom: -80px;
  `,
  code: css`
    max-width: 460px;
    border-radius: 6px;
    width: 100% !important;

    code,
    .token {
      font-family: 'Roboto Mono', monospace;
      line-height: 1.3;
      letter-spacing: 0.5px;
    }
  `,
  columnRight: css`
    flex: 1;
    width: 100%;
    min-width: unset;

    @media (min-width: 500px) {
      max-width: 380px;
      padding-left: 5px;
    }

    @media (max-width: 878px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
    }
  `,
  column: css`
    flex: 1;
    width: 100%;
    min-width: unset;

    @media (min-width: 500px) {
      min-width: 460px;
    }

    @media (max-width: 878px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  `,
  expoColumn: css`
    max-width: 450px;
    flex: 1;
  `,
  section: css`
    padding-bottom: 0px;
    // min-height: 1000px;
  `,
  innerSection: css`
    justify-content: center;
  `,
  snack: css`
    align-items: center;
    display: none;
    flex-flow: column;
    // padding-bottom: 45px;
    @media (min-width: 992px) {
      display: flex;
    }
    img {
      margin-bottom: 30px;
    }
    h1 {
      margin-top: 0;
    }
    p {
      color: ${theme.colors.nearBlack};
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 45px;
    }
  `,
  header: css`
    padding-bottom: 40px;
    h1 {
      margin-top: 0;
    }
    p {
      margin-bottom: 0;
    }
  `,
  editorWrapper: css`
    display: none;

    @media (min-width: 960px) {
      display: block;
    }
  `
};

function Code() {
  return (
    <Section outerStyles={styles.section} innerStyles={styles.innerSection}>
      <div className={styles.header}>
        <Heading>
          Achieve more <span>in less time</span>
        </Heading>
        <p className={styles.tagline}>
          Don't waste your time writing complex components from scratch.
        </p>
      </div>
      <div className={styles.comparison}>
        <div className={styles.column}>
          <h2>Standard React code</h2>
          <pre className={styles.code}>
            <CodeSnippet code={codeSnippetStandard} theme={grayTheme} />
          </pre>
        </div>
        <div className={styles.columnRight}>
          <h2>With react-native-paper</h2>
          <pre className={styles.code}>
            <CodeSnippet code={codeSnippetPaper} />
          </pre>
          <span role="img" aria-label="point up">
            👆
          </span>
          <p>
            Interactions, animations and accessibility.
            <br />
            React-native-paper takes care of the details and your UI logic, so
            you can focus on your users.
          </p>
        </div>
      </div>

      <div className={styles.editorWrapper}>
        <Editor />
      </div>
    </Section>
  );
}

export default Code;
