import React from 'react';
import { css } from 'linaria';
import { styled } from 'linaria/react';

import { theme } from '../utils/theme';

const Button = styled.button`
  background: ${({ dark }) =>
    dark ? theme.colors.primary : theme.colors.white};
  border-radius: 23px;
  border: 2px solid
    ${({ dark }) => (dark ? theme.colors.white : theme.colors.grey)};
  color: ${({ dark }) => (dark ? theme.colors.white : theme.colors.nearBlack)};
  cursor: pointer;
  height: 46px;
  justify-content: center;
  font-size: 16px;
  padding: 0px 24px;
  text-align: center;
  text-decoration: none;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out,
    border 0.3s ease-in-out;
  width: auto;

  &:disabled {
    cursor: initial;
  }

  &:hover {
    background: ${({ dark }) =>
      dark ? theme.colors.white : theme.colors.grey};
    border: 2px solid
      ${({ dark }) => (dark ? theme.colors.primary : theme.colors.grey)};
    color: ${({ dark }) =>
      dark ? theme.colors.primary : theme.colors.nearBlack};
  }
`;

export default Button;
