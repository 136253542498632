export const code = `<View style={ customStyle.box }>
  <Title>
    Sign up to our newsletter!
  </Title>
  <Paragraph>
    Get a monthly dose of fresh React Native Paper news straight to your mailbox. Just sign up to our newsletter and enjoy!
  </Paragraph>
  <Divider />
  <View style={ customStyle.row }>
  <Chip
    onPress={
      toggleTheme
    }
    selected={ isDarkTheme }
    style={{ marginRight: 8 }}
  >
    Dark theme
  </Chip>
  <Chip
    onPress={
     toggleMD3
    }
    selected={ isV3 }
    style={{ marginRight: 8 }}
  >
    Material You
  </Chip>
  </View>
  <Divider />
  <TextInput
    style={{ marginTop: 15 }}
    label='Outlined input'
    mode='outlined'
  />
  <TextInput
    style={{ marginTop: 15 }}
    label='Flat input'
    mode='flat'
  />
  <Button
    style={{ marginTop: 15 }}
    icon='send'
    mode='contained'
    onPress={ toggleSnack }
  >
    Sign me up
  </Button>
</View>
<Snackbar
  visible={ showSnack }
  onDismiss={ toggleSnack }
  action={{
    label: 'Dismiss',
    onPress: () => {
      // Do side magic
    },
  }}
  duration={
    Snackbar.DURATION_LONG
  }
>
  Hey there! I'm a Snackbar.
</Snackbar>`;

export const md3Code = `<View style={ customStyle.box }>
  <Text variant='headlineSmall'>
    Sign up to our newsletter!
  </Text>
  <Text variant='labelLarge'>
    Get a monthly dose of fresh React Native Paper news straight to your mailbox. Just sign up to our newsletter and enjoy!
  </Text>
  <Divider />
  <View style={ customStyle.row }>
  <Chip
    onPress={
      toggleTheme
    }
    style={{ marginRight: 8 }}
    selected={ isDarkTheme }
  >
    Dark theme
  </Chip>
  <Chip
    onPress={
     toggleMD3
    }
    style={{ marginRight: 8 }}
    selected={ isV3 }
  >
    Material You
  </Chip>
  </View>
  <Divider />
  <TextInput
    style={{ marginTop: 15 }}
    label='Outlined input'
    mode='outlined'
  />
  <TextInput
    style={{ marginTop: 15 }}
    label='Flat input'
    mode='flat'
  />
  <Button
    style={{ marginTop: 15 }}
    icon='send'
    mode='contained'
    onPress={ toggleSnack }
  >
    Sign me up
  </Button>
</View>
<Snackbar
  visible={ showSnack }
  onDismiss={ toggleSnack }
  action={{
    label: 'Dismiss',
    onPress: () => {
      // Do side magic
    },
  }}
  duration={
    Snackbar.DURATION_LONG
  }
>
  Hey there! I'm a Snackbar.
</Snackbar>`;


export const transformCode = code => {
  if (typeof code === 'string') {
    return `function MyComponent() {
      const lightTheme = {
        ...MD2LightTheme,
        dark: false,
        colors: {
          ...MD2LightTheme.colors,
          background: '#FFF'
        }
      };
      const darkTheme = {
        ...MD2LightTheme,
        dark: true,
        colors: {
          ...MD2LightTheme.colors,
          primary: '#64DAC5',
          background: '#202C32',
          text: '#FFF',
          placeholder: '#AAA'
        }
      };
  
      const md3LightTheme = {
        ...MD3LightTheme,
        dark: false,
      };
      const md3DarkTheme = {
        ...MD3DarkTheme,
        dark: true,
      };

    const [ isDarkTheme, setIsDarkTheme] = React.useState(false)
    const [ currentTheme, setCurrentTheme ] = React.useState(lightTheme)
    const [ showSnack, setShowSnack ] = React.useState(false);
  
    const dTheme = isV3 ? md3DarkTheme : darkTheme;
    const lTheme = isV3 ? md3LightTheme : lightTheme;
  
    const toggleSnack = () => {
      setShowSnack(!showSnack);
    }
  
    const toggleTheme = () => {
      if (isDarkTheme) {
        setIsDarkTheme(false);
        setCurrentTheme(lTheme)
      } else {
        setIsDarkTheme(true);
        setCurrentTheme(dTheme);
      }
    }
  
    const toggleMD3 = () =>  {
      setIsV3(!isV3);
    }

    return (
        <Provider theme={isDarkTheme ? dTheme : lTheme}>
          <View>
            <Appbar.Header
              statusBarHeight={47}
            >
              <Appbar.Content
                title="react-native-paper"
                subtitle="Read more in the docs"
              />
              <Text
                href="https://callstack.github.io/react-native-paper/appbar-header.html"
                accessibilityRole="link"
                target="_blank"
              >
                <Appbar.Action
                  icon="open-in-new"
                  color={!isV3 && "#FFF" }
                />
              </Text>
            </Appbar.Header>
            <ScrollContainer
              style={{ 
                height: 600,
                marginBottom: -30,
                overflow: 'scroll', 
                backgroundColor: currentTheme.colors.background 
              }}
              className="inputsWithoutOutline"
            >
              ${code}
            </ScrollContainer>
          </View>
        </Provider>
    )
  } 
    `;
  }
};
