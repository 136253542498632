import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';

import Section from '../layout/Section';
import Button from '../Button';
import Link from '../Link';

import callstack from '../../images/callstack-dark.svg';

const sectionOuterOverride = css`
  min-height: 400px;
  @media (max-width: 992px) {
    padding: 60px 15px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;

  & h2 {
    font-size: 3rem;
    margin-bottom: 40px;

    @media (min-width: 768px) {
      font-size: 4rem;
    }

    & .brand {
      color: rgba(33, 0, 93, 1);
    }
  }

  & h3 {
    margin-top: 40px;
    margin-bottom: 1rem;
    font-size: 30px;
    font-weight: 300;
  }

  & p {
    margin-top: 0;
    margin-bottom: 40px;
    font-weight: 300;
    width: 100%;
    line-height: 24px;
    @media (min-width: 768px) {
      width: 450px;
    }
  }

  img {
    width: 250px;
    @media (min-width: 768px) {
      width: 300px;
    }
  }

  text-align: center;
`;

function Callstack() {
  return (
    <Section outerStyles={sectionOuterOverride}>
      <Content>
        <h2>Paper is created by</h2>
        <img src={callstack} alt="Callstack logo"/>
        <h3>React Native Core Contributors and official Facebook partners</h3>
        <p>
          Callstack cooperates with many clients from various industries, including big 
          enterprises, helping them to improve their React Native products and achieve 
          business goals.
        </p>
        <Link to="https://callstack.com/">
          <Button dark>Visit Callstack</Button>
        </Link>
      </Content>
    </Section>
  );
}

export default Callstack;
