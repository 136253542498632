import React from 'react';
import { styled } from 'linaria/react';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 46px 20px 20px 20px;
  width: 300px;
  height: 360px;
  background-color: #FFF;
  box-shadow: 10px 10px 0px 0px rgba(98,0,238,.30);
  border: 2px solid #E6EAF2;
  border-radius: 4px;
  color: #414757
  text-align: center;
  margin-bottom: 15px;
  @media (min-width: 1000px) {
    margin-bottom: unset;
  }

  & h4 {
    font-size: 20px;
    font-weight: 300;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  & p {
    margin-top: 0;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    line-height: 24px;
  }

  & img {
    height: 64px;
  }
`;

function ConceptCard({ title, icon, description }) {
  return (
    <Card>
      <img src={icon} alt={title} />
      <h4>{title}</h4>
      <p>{description}</p>
    </Card>
  );
}

export default ConceptCard;
