import React from 'react';
import { css } from 'linaria';
import Highlight, { defaultProps } from 'prism-react-renderer';

import editorTheme from '../../utils/editorTheme';
import dots from '../../images/dots.png';

const beforeStyle = {
  snippet: css`
    &::before {
      content: '';
      width: 60px;
      height: 20px;
      background: url(${dots}) center top / contain no-repeat;
      display: block;
      margin-top: -10px;
      margin-left: -10px;
    }
  `
};

const customStyles = {
  padding: 20,
  overflowY: 'auto'
};

function CodeSnippet({ code, theme }) {
  return (
    <Highlight
      {...defaultProps}
      code={code}
      theme={theme || editorTheme}
      language="jsx"
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre
          className={`${beforeStyle.snippet} ${className}`}
          style={{ ...style, ...customStyles }}
        >
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
}

export default CodeSnippet;
